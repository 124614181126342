@import "../../../../styles/colors.scss";

.worksheetResults {
  display: flex;
  border-top: 2px solid $border-gray;
  padding-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  &Container {
    width: 100%;
    border-left: 3px solid $blue;
    font-weight: 700;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    &Description  {
      margin-top: 7px;
      font-weight: 400;
      list-style-type: disc; 
    }
    &Description li::marker {
      color: white; 
      font-size: 20px;
    }
  }
  &Item {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px 9px;
    margin-bottom: 5px;
    background-color: #545454;
    border-radius: 5px;
    font-family: "Roboto Mono", serif;
    font-weight: 300;
    line-height: 16px;
    font-size: 12px;
    &Container {
      display: flex;
      align-items: center;
    }
    &MinifiedContainer {
      display: flex;
      flex-direction: column;
      font-family: "Roboto Mono", serif;
      margin-top: 8px;
      &Reason {
        background-color: $sand;
        border-radius: 5px;
        margin-top: 16px;
        padding: 8px;
        color: $black;
      }
    }
    span {
      font-family: "Roboto Mono", serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }
    ul {
      list-style-position: inside;
      list-style-type: none;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      color: $blue
    }
    p {
      font-weight: 400;
    }
    svg {
      margin-left: auto;
      cursor: pointer;
    }
  }
  &Item:last-child {
    margin-bottom: 0;
  }
  &Separator {
    height: 17px;
    border-left: 1px solid $white;
    margin: 0px 5px
  }
}


@media (max-width: 1024px) {
  .worksheetResultsItem {
    p {
      display: none
    }
  }
  .worksheetResultsSeparator {
    display: none
  }
}


