@import "../../../../styles/colors.scss";

.chatComponent {
  border-bottom: 1px solid $border-gray;
  padding: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

@media (max-width: 1024px) {
  .chatComponent {
    overflow-y: scroll;
  }
}