
@import "../../styles/colors.scss";

.agentChat {
  width: 350px;
  height: 100%;
  background-color: $black;
  border-radius: 10px;
  border: 2px solid $border-gray;
  padding: 12px;
  display: flex;
  flex-direction: column;
  &Header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
    padding-bottom: 12px;
    border-bottom: 2px solid $border-gray;
    &Img {
      width: 56px;
      height: 56px;
      background-color: $background-gray;
      border-radius: 50%;
      margin-right: 12px;
    }
    &Description {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-weight: 600;
      p:last-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.chatColapseIcon {
  display: none;
}


@media (max-width: 1024px) {
  .agentChat {
    z-index: 50;
    background-color: $black;
    border-radius: 30px 30px 0 0;
    width: 100%;
    height: initial;
    position: absolute;
    bottom: 0;
    right: 24px;
    left: 0;
    transition: height 0.2s ease-in-out; 
    overflow: hidden;
    &Header { 
      padding: 12px;
      height: 80px;
    }
  }
  .chatColapseIcon {
    display: flex;
    margin-left: auto;
    stroke: $colapse-icon-stroke;
    &Disabled {
      stroke: $border-gray
    }
  }
}