@import './styles/colors.scss';

.app {
  height: 100%;
  width: 100%;
  
  &Header {
    height: 71px;
    width: 100%;
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    &LogOut {
      cursor: pointer;
      font-weight: 500;
    }
    img {
      height: 70%
    }
  }
}


@media (max-width: 1024px) {
  .app {
    &Header {
      img {
        height: 60%
      }
    }
  }
}