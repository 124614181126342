.chat-bubble {
  display: inline-block;
  background: #31AED6!important;  /* Light gray background */
  padding: 10px 20px;
  border-radius: 18px;
  max-width: 80px;
  margin: 10px 0;
}

.loading-container {
  display: flex;
  justify-content: space-between;
  width: 40px;  /* Adjust width of the dot container */
}

.dot {
  width: 7.5px;
  height: 7.5px;
  background-color: black;  /* Dark gray for the dots */
  border-radius: 50%;
  animation: bounce 3s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  100% {
    transform: translateY(-5px);
  }
  75% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  25% {
    transform: translateY(0px);
  }
  0% {
    transform: translateY(-5px);
  }
}