@import "../../../../styles/colors.scss";

.chatInput {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: end;
  &TextArea {
    position: relative;
    width: 100%;
    height: 75px;
    textarea {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $bubble-gray;
      color: $white;
      border: 2px solid $black;
      border-radius: 10px;
      &:hover,
      &:focus {
        background-color: $bubble-gray;
        color: $white;
        border: 2px solid $black;
      }
    }
    &Send {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 30%;
      height: 27px;
      width: 27px;
      border-radius: 50%;
      background-color: $black;
      cursor: pointer;
      svg {
        color: $white;
      }
    }
    &Placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $bubble-gray;
      border: 2px solid $black;
      border-radius: 10px;
      z-index: 50;
      position: absolute;
      top: 5px;
      bottom: 0;
      right: 0;
      left: 0;
      font-size: 15px;
      line-height: 18.15px;
    }
  }
  &Microphone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 29px;
    text-align: center;
    cursor: pointer;
    p {
      margin-top: 8px;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #9B9B9B;
    }
    svg {
      height: 29px;
      width: 29px;
    }
  }
}


@media (max-width: 1024px) {
  .chatInput {
    height: 80px!important;
  }
}