@import '../../styles/colors.scss';

.dashboard {
  &Container {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 24px 12px;
    background-color: $background-gray
  }
}


@media (max-width: 576px) {
  .dashboard {
    &Container {
      width: 100%;
      display: flex;
      padding: 0;
      background-color: $background-gray
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard {
    width: 80%;
  }
}