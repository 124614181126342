@import "../../../../styles/colors.scss";

.worksheetProcess {
  display: flex;
  border-top: 2px solid $border-gray;
  padding-top: 15px;
  margin-bottom: 15px;
  width: 100%;

  &Container {
    width: 100%;
    border-left: 3px solid $blue;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    span {
      text-decoration: underline;
      color: $blue;
      font-weight: 300;
      cursor: pointer
    }
    svg {
      cursor: pointer;
      margin-right: 8px
    }
    span::before {
      color: $white;
      content: "[ ";
    }
    span::after {
      color: $white;
      content: " ]";
    }
    &Description  {
      margin-top: 7px;
      font-weight: 400;
    }
    &List {
      width: 70%;
      margin-top: 7px;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      flex-direction: column;
      padding: 8px;
      font-family: "Roboto Mono", serif;
      background-color: #545454;
      border-radius: 5px;
    }
  }
  ul {
    list-style-position: inside;
    list-style-type: none;
  }
  li::before {
    content: "• ";
    color: white; 
  }
}