@import "../../../../styles/colors.scss";

.worksheetJob {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 3px solid $border-gray;
  background-color: $black;
  margin-bottom: 16px;
  padding: 11px;
  &Active {
    border: 3px solid $blue;
  }
  &Details {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &Header {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-right: 8px;
    p:last-child {
      margin-top: 8px;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
    }
  }
  &Tasks {
    margin-top: 15px;
  }

  &File {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #5555;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    background-color: $background-gray;
    width: 30%;
    margin-top: 8px;
    img {
      height: 21px;
      margin-right: 8px;
    }
    p {
      font-weight: 400;
      margin-right: 8px;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      color: $blue
    }
  }
}


@media (max-width: 1024px) {
  .worksheetJob {
    &File {
      width: 100%;
    }
  }
}