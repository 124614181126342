
@import "../../../../styles/colors.scss";

.chatMessage {
  width: 100%;
  display: flex;
  flex-direction: column;
  ul {
    list-style-position: inside;
  }
  li::marker {
    color: black; 
    font-size: 20px;
  }
  div:first-child {
    width: 80%;
    background-color: $blue;
    padding: 10px 14px;
    border-radius: 18px;
    font-size: 14px;
    margin-bottom: 15px;
    p {
      color: $black!important
    }
  }
  &Sent {
    div:first-child  {
      background-color: $bubble-gray;
      margin-left: auto;
      p {
        color: $white!important
      }
    }
  }
}