@import "../../../../styles/colors.scss";

.workHistory {
  display: flex;
  width: 100%;
  border: 1px solid $border-gray;
  padding: 8px;
  border-radius: 5px;
  background-color: $background-gray;
  align-items: center;
  cursor: pointer;
  img {
    height: 22px;
    margin-right: 8px;
  }
  &Active {
    border: 2px solid $blue;
  }
}

.workHistory:not(:first-child) {
  margin-top: 8px;
}