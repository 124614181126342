@import '../styles/colors.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden
}

body {
  font-family: "Roboto", sans-serif;
  background-color: $background-gray;
  background-size: cover;
  position: fixed; 
  left: 0;
  top: 0; 
  right: 0;
  bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: $white;
  font-weight: bold;
}

p,li,ul,ol {
  padding: 0;
  margin: 0;
  color: $white;
}

textarea {
  padding: 4px 45px 4px 11px!important;
}

body {
  scrollbar-width: thin;
  scrollbar-color: $blue $background-gray;
}
