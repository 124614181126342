@import "../../../../styles/colors.scss";

.worksheetTracker {
  display: flex;
  border-top: 2px solid $border-gray;
  padding-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  &Container {
    width: 100%;
    border-left: 3px solid $blue;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    &Description  {
      font-weight: 400;
      list-style-type: disc; 
      margin-top: 7px;
    }
    &Description li::marker {
      color: white; 
      font-size: 20px;
    }
  }

  &File {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #5555;
    font-size: 14px;
    line-height: 17px;
    background-color: $background-gray;
    margin-top: 17px;
    margin-right: auto;
    img {
      height: 21px;
      margin-right: 8px;
    }
    p {
      font-weight: 400;
      margin-right: 8px;
    }
    a {
      text-decoration: underline;
      font-weight: 700;
      color: $blue
    }
  }
}


@media (max-width: 1024px) {
  .worksheetTracker {
    &File {
      width: 100%;
    }
  }
}