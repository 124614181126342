@import "../../styles/colors.scss";

.workHistory {
  width: 100%;
  height: 50%;
  padding: 14px;
  background-color: $black;
  border: 2px solid $border-gray;
  border-radius: 10px;
  padding: 12px;
  overflow: hidden;
  &Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
    svg {
      height: 27px;
      fill: $white
    }
  }
  &Container {
    height: 85%;
    overflow-y: auto
  }
  &LoadMore {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
  }
}

@media (max-width: 1024px) {
  .workHistory {
    height: inherit;
    &Header {
      display: none;
    }
    &Container {
      height: 70%;
    }
  }
}