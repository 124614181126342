@import "../../styles/colors.scss";

.worksheet {
  height: 100%;
  flex: 1;
  display: block;
  margin: 13px;
  position: relative;
  &Header {
    display: flex;
    justify-content: space-between;
    padding: 13px;
    border-bottom: 2px solid $border-gray;
    &Title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      p:last-child {
        font-size: 15px;
        line-height: 18px;
      }
    }
    &Links {
      display: none
    }
  }
  &Content {
    max-height: 88.5%;
    margin-top: 15px;
    padding-right: 13px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.renderedComponentContainer {
  display: none;
}



@media (max-width: 1024px) {
  .activeSVG {
    svg {
    fill: white!important
    }
  }
  .worksheet {
    margin: 0;
    &Header { 
      padding: 13px;
      border-bottom: 0px;
      align-items: center;
      &Title {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
      }
      &Links {
        display: flex;
        svg:first-child {
          margin-right: 12px;
        }
        img,
        svg {
          height: 27px;
          fill: $colapse-icon-stroke
        }

      }
    }
  }
  .renderedComponentContainer {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
    margin-bottom: 70px;
  }
}