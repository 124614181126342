@import '../../styles/colors.scss';

.loginPage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: $background-gray;
  background-color: $black;
  &Tile {
    width: 30%;
    height: 30%;
    min-height: 320px;
    // background-color: $black;
    background-color: $background-gray;
    border-radius: 10px;
    padding: 24px;
    &Container {
      height: 100%;
      width: 100%;
      img {
        width: 100%;
      }
    }
    &Button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.googleButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  background-color: $bubble-gray;
  border-radius: 22px;
  padding: 12px;
  svg {
    height: 25px;
    margin-right: 8px;
  }
}

.error {
  margin-top: 10px;
  height: 30px;
  color: white;
}


@media (max-width: 576px) {
  .loginPageTile {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .loginPageTile {
    width: 80%;
  }
}