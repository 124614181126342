@import "../../../../styles/colors.scss";

.worksheetTask {
  display: flex;
  border-top: 2px solid $border-gray;
  padding-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  &Container {
    width: 100%;
    border-left: 3px solid $blue;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    &Description  {
      font-weight: 400;
      list-style-type: disc; 
      padding-left: 20px; 
    }
    &Description li::marker {
      color: white; 
      font-size: 20px;
    }
  }
}