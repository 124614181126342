@import "../../../../styles/colors.scss";

.quickLink {
  display: flex;
  width: 100%;
  border: 1px solid $border-gray;
  padding: 8px;
  border-radius: 5px;
  background-color: $background-gray;
  cursor: pointer;
  align-items: center;
  img {
    height: 22px;
    margin-right: 8px;
  }
}

.quickLink:not(:first-child) {
  margin-top: 8px;
}